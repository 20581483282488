@import '../../../../../components/UI/DesignSystem.scss';

.ProjectTree {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;

	> svg {
		margin: 0 0 5px 10px;
	}
}
