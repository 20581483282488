@import '../../components/UI/DesignSystem.scss';

$width: 326px;

.LabelsGroupsModal {
	position: fixed;
	left: calc(50% - (#{$width} / 2));
	top: 20%;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: $width;
	background-color: white;
	border-radius: 4px;

	.linkToSettings {
		font-weight: 400;
		color: $color-primary-base;
		text-decoration: underline;
	}

	.modalHeader {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		width: calc(100% - 32px);
		height: 28px;
		background-color: $color-white;
		border-bottom: 1px solid $color-middle-grey;
		border-radius: 4px 4px 0 0;
		padding: 12px 16px;

		.title {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 100%;
			gap: 71px;

			h4 {
				color: $color-secondary-dark;
			}

			.IconButton {
				display: flex;
				align-items: center;
			}
		}

		&.no-groups, &.cannot-manage-groups {
			justify-content: center;
		}
	}

	.emptyState {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 80%;
		height: 100%;
		padding: 16px;
	
		p {
			text-align: center;
			font-size: 14px;
			font-weight: 400;
			color: $color-black;
		}

		.linkToSettings {
			font-size: 14px;
		}
	}

	.modalFooter {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		background: $color-app-background;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;

		p {
			font-size: 12px;
			font-weight: 400;
		}

		.linkToSettings {
			font-size: 12px;
		}
	}

	.loading {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 12px;
	}

	.GroupsList {
		width: calc(100% - 24px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		align-self: flex-start;
		padding-top: 12px;
		margin: 0 12px;

		.GroupItem {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			height: 37px;
			border-bottom: 1px solid $color-light-grey;

			.Checkbox {
				margin: 0 12px;

				p {
					font-size: 14px;
					font-weight: 600;
				}
			}
		}

		.GroupItem:last-child {
			border-bottom: unset;
		}

		.GroupItem label {
			cursor: pointer;
		}

		.GroupItem:hover {
			background-color: $color-primary-hover;
		}
	}

	.actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		gap: 20px;
		padding: 12px 20px 12px 0;

		.Button {
			min-width: 60px;
			max-width: 60px;
		}

		.cancel-button {
			color: $color-black;
		}

		.confirm-button {
			background-color: $color-primary-base;
			color: $color-white;
		}

		&.no-groups {
			justify-content: center;
			padding: 0 0 12px;
		}
	}
}